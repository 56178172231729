<template>
  <base-table-row
    :columns="columns"
    v-bind="item"
    class="contact-table-row"
    col-class="contact-table-row__col"
  >
    <template #fullname>
      {{ item.fullname || '' }}
    </template>
    <template #domains>
      {{ item.domains }}
    </template>
    <template v-if="contextMenu.length" #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="contact-table-row__popover"
      >
        <plain-button
          icon="more"
          class="contact-table-row__more"
          @click="togglePopoverState"
        /><template #popover>
          <base-context-menu
            :tariff="item"
            :menu="contextMenu"
            :module-main="moduleMain"
            :tools="tools"
            class="contact-table-row__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import popoverFix from '@/mixins/popoverFix';
import BaseContextMenu from '@/components/BaseContextMenu/BaseContextMenu.vue';
import payersContextMenu from '../../../mixins/contactsContextMenu';
export default {
  name: 'ContactsTableRow',
  components: { BaseTableRow, BaseContextMenu },
  mixins: [popoverFix, payersContextMenu],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moduleMain: 'moduleDomains.moduleDomainsContact',
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsContact.tools;
    },
    current() {
      return this.item;
    },
  },
};
</script>

<i18n>
{
  "ru": {
  }
}
</i18n>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.contact-table-row {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
